<template>
    <v-card>
        <v-card-title class="font-weight-bold">e) Representación gráfica del mercado</v-card-title>
        <v-card-text>
            <DataTableComponent
                :items="limites"
                :headers="headers"
                :tiene_paginacion="false"
                class="mt-2 mb-4"
            />
            <apexchart 
                v-if="mostrarInformacion"
                width="100%" 
                type="scatter" 
                :options="options" 
                :series="series"
                id="chart"
            />
        </v-card-text>
    </v-card>
</template>
<script>
import DataTableComponent from '@/components/DataTableComponent.vue';

export default {
    name: 'RepresentacionGrafica',
    props: {
        ofertas: { type: Array },
        promedio: { type: Number },
        desviacionEstandar: { type: Number },
        mostrarInformacion: { type: Boolean },
    },
    data: () => ({
        headers: [
            { text: 'Límite inferior', value: 'limite_inferior', align: 'center', sortable: false },
            { text: 'Límite superior', value: 'limite_superior', align: 'center', sortable: false },
        ],
    }),
    computed: {
        limiteInferior() {
            return this.promedio - this.desviacionEstandar;
        },
        limiteSuperior() {
            return this.promedio + this.desviacionEstandar;
        },
        limites() {
            return [
                { 
                    limite_inferior: `$ ${this.mostrarInformacion ? this.limiteInferior.toFixed(2) : '-'}`,
                    limite_superior: `$ ${this.mostrarInformacion ? this.limiteSuperior.toFixed(2) : '-'}`,
                },
            ];
        },
        series() {
            return this.ofertas.map((oferta) => ({
                name: oferta.proveedor,
                data: [[Number(oferta.precio_unitario), Number(oferta.precio_unitario)]],
            }));
        },
        options() {
            // rangos
            const ofertasOrdenadas = this.ofertas.slice().sort((a, b) => a.precio_unitario - b.precio_unitario);
            const yAxisMin = Math.min(this.limiteInferior.toFixed(2), Number(ofertasOrdenadas[0].precio_unitario));
            const yAxisMax = Math.max(this.limiteSuperior.toFixed(2), Number(ofertasOrdenadas[ofertasOrdenadas.length - 1].precio_unitario));
            return ({
                chart: {
                    height: 350,
                    type: 'scatter',
                    zoom: {
                        enabled: true,
                        type: 'xy'
                    },
                },
                xaxis: {
                    min: yAxisMin - (yAxisMin / 2),
                    max: yAxisMax + (yAxisMax / 2),
                    tickAmount: 10,
                    labels: {
                        formatter: function (value) {
                            return `$${value?.toFixed(2)}`;
                        },
                    },
                },
                yaxis: {
                    min: yAxisMin,
                    max: yAxisMax,
                    tickAmount: 10,
                    labels: {
                        show: false,
                        formatter: function (value) {
                            return `$${value?.toFixed(2)}`;
                        },
                    },
                },
                annotations: {
                    points: [
                        {
                            x: this.limiteInferior,
                            y: this.limiteInferior,
                            marker: {
                                size: 8,
                                shape: 'square',
                                fillColor: '#775DD0',
                                strokeWidth: 0,
                            },
                            label: {
                                borderColor: 'transparent',
                                style: {
                                    cssClass: 'calc-limite-inferior-label',
                                    background: 'transparent',
                                },
                                text: `Límite inferior ($${this.limiteInferior.toFixed(2)})`,
                            },
                        },
                        {
                            x: this.limiteSuperior,
                            y: this.limiteSuperior,
                            marker: {
                                size: 8,
                                shape: 'square',
                                fillColor: '#775DD0',
                                strokeWidth: 0,
                            },
                            label: {
                                borderColor: 'transparent',
                                style: {
                                    cssClass: 'calc-limite-superior-label',
                                    background: 'transparent',
                                },
                                text: `Límite superior ($${this.limiteSuperior.toFixed(2)})`,
                            },
                        },
                    ],
                },
                grid: {
                    xaxis: {
                        lines: {
                            show: true,
                        },
                    },
                    yaxis: {
                        lines: {
                            show: true,
                        },
                    },
                },
                dataLabels: {
                    enabled: true,
                    enabledOnSeries: undefined,
                    formatter: function (val) {
                        return `$${val.toFixed(2)}`;
                    },
                    offsetX: 0,
                    offsetY: -8,
                    style: {
                        fontWeight: 500,
                        colors: ['transparent'],
                    },
                    background: {
                        enabled: true,
                        foreColor: '#000',
                        borderColor: 'transparent',
                        opacity: 0.3,
                    },
                },
            });
        },
    },
    components: { DataTableComponent }
}
</script>
<style scoped>
    :deep(#chart .apexcharts-canvas) {
        padding-top: 16px !important;
    }

    :deep(#chart .apexcharts-toolbar) {
        right: 20px;
    }

    :deep(#chart rect:has(+ .calc-limite-inferior-label)),
    :deep(#chart rect:has(+ .calc-limite-superior-label)) {
        background-color: yellow !important;
    }

    :deep(.calc-limite-inferior-label),
    :deep(.calc-limite-superior-label) {
        font-weight: bold !important;
    }

    :deep(#chart .apexcharts-point-annotation-marker) {
        clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%) !important;
    }
</style>