<template>
    <v-dialog v-model="open" max-width="700px">
        <div style="background-color: #FFFFFF; padding: 0 1em 2em; position: relative;">
            <div class="pt-4" style="width: 100%; background-color: inherit; position: sticky; top: 0; z-index: 10;">
                <v-btn 
                    style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                    class="px-4 py-4"
                    rounded
                    text
                    @click.stop="cerrarModal"
                >
                    <v-icon>mdi-window-close</v-icon>
                </v-btn>
            </div>
            <div class="px-1">
                <div class="d-flex justify-center align-center" style="gap: 4px">
                    <v-icon style="font-size: 36px !important;">mdi-information-outline</v-icon>
                    <h4 class="text-center text-uppercase font-weight-bold text-h5">importante</h4>
                </div>
                <p class="text-center text-body-1 mt-4">
                    Esta herramienta de cálculo
                    no sustituye la responsabilidad de las
                    instituciones y sus delegados a evaluar y analizar precios; ni
                    constituye una condición automática para rechazar ofertas.
                </p>
                <v-btn @click.stop="cerrarModal" class="d-block mx-auto mt-8" color="primary">Cerrar</v-btn>
            </div>
        </div>
    </v-dialog>
</template>
<script>


export default {
    name: 'ModalInformativa',
    emits: ['on-close'],
    models: [
        { data: "open", event: "models:open" },
    ],
    data: () => ({
        open: true,  
    }),
    methods: {
        cerrarModal() {
            this.$emit('on-close');
        },
    },
}
</script>