<template>
    <v-card>
        <v-card-title class="font-weight-bold">c) Cumplimiento del criterio de 2 desviaciones estándar</v-card-title>
        <v-card-text>
            <v-list dense>
                <v-list-item>
                    <v-list-item-content class="font-weight-bold">
                        Criterio:
                    </v-list-item-content>
                    <v-list-item-content class="align-end">
                        $ {{ toNumberFormat(criterio2DesviacionesEstandar) }}
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    name: 'CumplimientoCriterio',
    props: {
        criterio2DesviacionesEstandar: { type: Number },
        ofertas: { type: Array },
        mostrarInformacion: { type: Boolean },
    },
    methods: {
        toNumberFormat(valor) {
            return this.mostrarInformacion ? valor.toFixed(2) : '-';
        },
    },
}
</script>