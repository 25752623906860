<template>
    <v-card>
        <v-card-title class="font-weight-bold">d) Cumplimiento del ratio de dispersión</v-card-title>
        <v-card-text>
          <div class="d-flex justify-space-between align-center" style="gap: 16px">
            <p class="font-weight-bold mb-0" style="color: #313945">
              Ratio ({{ toPercentageFormat(ratioDispersion) }}):
            </p>

            <v-chip 
              label 
              :color="coloresCumplimientoRatio.background" 
              :text-color="coloresCumplimientoRatio.color"
            >
              {{ textoCumplimientoRatio }}
            </v-chip>
          </div>

          <DataTableComponent
            class="mt-4"
            :headers="headers"
            :tiene_paginacion="false"
            :items="ofertas"
          >
            <template v-slot:item.precio_unitario="{ item }">
              <span class="font-weight-bold">$ {{ toNumberFormat(item.precio_unitario) }}</span>
            </template>
            <template v-slot:item.desviacion_estandar="{ item }">
              <div class="d-flex justify-center">
                <v-chip 
                  label
                  class="d-inline-block flex-grow-0 flex-shrink-1" 
                  style="width: auto !important;"
                  :color="obtenerColorFondoItem(item)"
                  :text-color="obtenerColorTextoItem(item)"
                >
                  {{ obtenerTextoItem(item) }}
                </v-chip>
              </div>
            </template>
          </DataTableComponent>
        </v-card-text>
    </v-card>
</template>
<script>
import DataTableComponent from '@/components/DataTableComponent.vue';

export default {
    name: 'RatioDispersion',
    components: { DataTableComponent },
    props: {
      ofertas: { type: Array },
      promedio: { type: Number },
      desviacionEstandar: { type: Number },
      criterio2DesviacionesEstandar: { type: Number },
      mostrarInformacion: { type: Boolean },
    },
    data: () => ({
      headers: [
        { text: 'Proveedor', value: 'proveedor', align: 'center', sortable: false },
        { text: 'Precio unitario', value: 'precio_unitario', align: 'center', sortable: false },
        { text: 'Desviaciones estándar', value: 'desviacion_estandar', align: 'center', sortable: false },
      ],
    }),
    computed: {
      ratioDispersion() {
        return this.desviacionEstandar / this.promedio;
      },
      textoCumplimientoRatio() {
        if (!this.mostrarInformacion) return '-';

        return (this.ratioDispersion * 100).toFixed(2) < 100 ? 'Cumple' : 'No cumple';
      },
      coloresCumplimientoRatio() {
        if (!this.mostrarInformacion) return {};

        return (this.ratioDispersion * 100).toFixed(2) < 100 ? { background: '#4fb786', color: '#FFF !important' } : {};
      },
    },
    methods: {
      toNumberFormat(value) {
        return Number(value).toFixed(2);
      },
      toPercentageFormat(value) {
        if (!this.mostrarInformacion) return '-';

        return Number.isNaN(value) ? '0.00%' : `${(value * 100).toFixed(2)}%`;
      },
      obtenerColorTextoItem(item) {
        if (!this.mostrarInformacion) return null;

        return item.precio_unitario <= this.criterio2DesviacionesEstandar ? '#FFF !important' : null;
      },
      obtenerColorFondoItem(item) {
        if (!this.mostrarInformacion) return null;

        return item.precio_unitario <= this.criterio2DesviacionesEstandar ? '#4fb786' : null;
      },
      obtenerTextoItem(item) {
        if (!this.mostrarInformacion) return '-';

        return item.precio_unitario <= this.criterio2DesviacionesEstandar ? 'Cumple' : 'No cumple';
      },
    },
}
</script>