<template>
    <v-container>
      <NavButton class="mt-4" />
        <v-row class="mt-4">
            <v-col cols="12" lg="6" class="d-flex flex-column flex-wrap" style="gap: 16px;">
                <v-card class="px-4 py-4">
                    <v-card-title class="font-weight-bold">a) Ofertas</v-card-title>
                    <v-card-text>
                      <v-alert dense type="info">Agregue al menos 3 ofertas para realizar los cálculos.</v-alert>

                        <v-form ref="form" @submit.prevent="manejarEnvioFormulario">
                          <div class="d-flex justify-center align-center flex-wrap" style="gap: 16px">
                            <v-text-field
                              v-model="form.proveedor"
                              label="Proveedor"
                              placeholder="Nombre del proveedor" 
                              :error-messages="proveedorErrors"
                              style="min-width: 200px"
                              class="flex-grow-1 flex-shrink-1"
                              outlined
                              @change="$v.form.proveedor.$touch()"
                              @blur="$v.form.proveedor.$touch()"
                            />
                            <v-text-field
                              v-model="form.precio_unitario"
                              label="Precio unitario"
                              placeholder="Precio unitario" 
                              :error-messages="precio_unitarioErrors"
                              style="min-width: 200px"
                              class="flex-grow-1 flex-shrink-1"
                              outlined
                              @change="$v.form.precio_unitario.$touch()"
                              @blur="$v.form.precio_unitario.$touch()"
                            />
                          </div>
                          <v-btn 
                            color="primary" 
                            type="submit" 
                            large
                          >
                            Agregar oferta
                          </v-btn>
                        </v-form>

                        <DataTableComponent
                          class="mt-4"
                          :headers="headers"
                          :items="ofertas"
                          :tiene_paginacion="false"
                        >
                          <template v-slot:item.precio_unitario="{ item }">
                            <span class="font-weight-bold">$ {{ toNumberFormat(item.precio_unitario) }}</span>
                          </template>
                          <template v-slot:item.acciones="{ item }">
                            <div class="d-flex align-center justify-center py-1">
                              <v-btn @click.stop="eliminarOferta(item)" icon>
                                  <v-icon color="secondary" x-small>mdi-trash-can</v-icon>
                              </v-btn>
                            </div>
                          </template>
                        </DataTableComponent>
                    </v-card-text>
                </v-card>

                <RatioDispersion
                  class="px-4 py-4"
                  :ofertas="ofertas"
                  :desviacion-estandar="desviacionEstandar"
                  :criterio2-desviaciones-estandar="criterio2DesviacionesEstandar"
                  :promedio="promedio"
                  :mostrar-informacion="mostrarInformacion"
                />
            </v-col>
            <v-col cols="12" lg="6" class="d-flex flex-column flex-wrap" style="gap: 16px;">
              <MedidasEstadisticas class="px-4 py-4" 
                :promedio="promedio"
                :desviacion-estandar="desviacionEstandar"
                :mediana="mediana"
                :ofertas="ofertas"
                :mostrar-informacion="mostrarInformacion"
              />

              <CumplimientoCriterio 
                class="px-4 py-4"
                :criterio2-desviaciones-estandar="criterio2DesviacionesEstandar"
                :ofertas="ofertas"
                :mostrar-informacion="mostrarInformacion"
              />

              <RepresentacionGrafica 
                class="px-4 py-4" 
                :ofertas="ofertas" 
                :desviacion-estandar="desviacionEstandar" 
                :promedio="promedio"
                :mostrar-informacion="mostrarInformacion"
              />
            </v-col>
        </v-row>
        <ModalInformativa 
          v-models:open="modalInformativaAbierta"
          @on-close="modalInformativaAbierta = false"
        />
    </v-container>
</template>
<script>
import DataTableComponent from '@/components/DataTableComponent.vue';
import RatioDispersion from './components/RatioDispersion.vue';
import MedidasEstadisticas from './components/MedidasEstadisticas.vue';
import CumplimientoCriterio from './components/CumplimientoCriterio.vue';
import RepresentacionGrafica from './components/RepresentacionGrafica.vue';
import ModalInformativa from './components/ModalInformativa.vue';
import { NavButton } from '@/components/utils';
import { required, decimal } from 'vuelidate/lib/validators';
import { Validator } from '@/utils/form-validation';

const dataInicialFormulario = {
    proveedor: null,
    precio_unitario: null,
};

export default {
    components: { 
      DataTableComponent, 
      RatioDispersion, 
      MedidasEstadisticas, 
      CumplimientoCriterio, 
      RepresentacionGrafica, 
      NavButton,
      ModalInformativa,
    },
    validations() {
        return ({
            form: {
                proveedor: { required },
                precio_unitario: { required, decimal },
            },
        });
    },
    data: () => ({
      localId: 0,
      form: dataInicialFormulario,
      ofertas: [],
      headers: [
        { text: 'Proveedor', value: 'proveedor', align: 'center', sortable: false },
        { text: 'Precio unitario', value: 'precio_unitario', align: 'center', sortable: false },
        { text: 'Acciones', value: 'acciones', align: 'center', sortable: false },
      ],
      modalAgregarOfertasAbierta: false,
      modalInformativaAbierta: true,
    }),
    computed: {
      // Formulario
      proveedorErrors() {
        return new Validator(this.$v.form.proveedor).detect().getResult();
      },
      precio_unitarioErrors() {
        return new Validator(this.$v.form.precio_unitario).detect().getResult();
      },
      // UI
      mostrarInformacion() {
        return this.ofertas.length > 2;
      },
      promedio() {
        const sumatoriaData = this.ofertas.reduce((prev, item) => prev + Number(item.precio_unitario), 0);
        const cantidad = this.ofertas.length;
        const resultado = sumatoriaData / cantidad;

        return Number.isNaN(resultado) ? 0 : resultado;
      },
      mediana() {
        if (!this.ofertas.length) return 0;

        const ofertasOrdenadas = this.ofertas.slice().sort((a, b) => a.precio_unitario - b.precio_unitario);
        const cantidad = this.ofertas.length;
        let mediana = 0;

        // Calcular la mediana
        if (cantidad % 2 === 0) {
            const medio1 = ofertasOrdenadas[cantidad / 2 - 1].precio_unitario;
            const medio2 = ofertasOrdenadas[cantidad / 2].precio_unitario;
            mediana = (Number(medio1) + Number(medio2)) / 2;
        } else {
            mediana = Number(ofertasOrdenadas[Math.floor(cantidad / 2)].precio_unitario);
        }

        return mediana;
      },
      desviacionEstandar() {
        if (!this.ofertas.length) return 0;

        // Calcular la suma de los cuadrados de las diferencias
        const sumatoriaCuadradosDiferencias = this.ofertas.reduce((suma, item) => {
            const diferencia = item.precio_unitario - this.promedio;
            return suma + (diferencia * diferencia);
        }, 0);

        // Calcular la varianza y luego la desviación estándar
        const varianza = sumatoriaCuadradosDiferencias / ( this.ofertas.length - 1);
        const desviacionEstandar = Math.sqrt(varianza);

        return desviacionEstandar;
      },
      criterio2DesviacionesEstandar() {
        return Number(this.promedio) + (this.desviacionEstandar * 2);
      },
    },
    methods: {
      clearForm() {
        this.$v.form.$reset();
        this.$refs.form?.reset();
      },
      manejarEnvioFormulario() {
        this.$v.$touch();

        if (this.$v.$invalid) return;

        const nuevaOferta = { ...this.form, id: this.localId++ };

        this.ofertas.push(nuevaOferta);
        this.pushAppMessage({ type: 'success', message: 'Oferta agregada.' });
        this.clearForm();
      },
      cerrarModalAgregarOfertas(visible) {
        this.modalAgregarOfertasAbierta = visible;
      },
      eliminarOferta(itemEliminar) {
        const nuevaListaOfertas = this.ofertas.filter((item) => item.id !== itemEliminar.id);
        this.ofertas = nuevaListaOfertas;
        this.pushAppMessage({ type: 'success', message: 'Oferta eliminada.' })
      },
      toNumberFormat(value) {
        return Number(value).toFixed(2);
      },
    },
}
</script>