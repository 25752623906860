<template>
    <v-card>
        <v-card-title class="font-weight-bold">b) Medidas de tendencia central y dispersión</v-card-title>
        <v-card-text>
            <v-list dense>
                <v-list-item>
                    <v-list-item-content class="font-weight-bold">
                        Promedio:
                    </v-list-item-content>
                    <v-list-item-content class="align-end">
                        $ {{ toNumberFormat(promedio) }}
                    </v-list-item-content>
                </v-list-item>

                <v-list-item class="py-2" style="min-height: unset !important;">
                    <v-list-item-content class="py-0">
                        <v-divider />
                    </v-list-item-content>
                </v-list-item>

                <v-list-item>
                    <v-list-item-content class="font-weight-bold">
                        Mediana:
                    </v-list-item-content>
                    <v-list-item-content class="align-end">
                      $ {{ toNumberFormat(mediana) }}
                    </v-list-item-content>
                </v-list-item>

                <v-list-item class="py-2" style="min-height: unset !important;">
                    <v-list-item-content class="py-0">
                        <v-divider />
                    </v-list-item-content>
                </v-list-item>

                <v-list-item>
                    <v-list-item-content class="font-weight-bold">
                        Desviación estándar:
                    </v-list-item-content>
                    <v-list-item-content class="align-end">
                      $ {{ toNumberFormat(desviacionEstandar) }}
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    name: 'MedidasEstadisticas',
    props: {
      promedio: { type: Number },
      mediana: { type: Number },
      desviacionEstandar: { type: Number },
      ofertas: { type: Array },
      mostrarInformacion: { type: Boolean },
    },
    methods: {
        toNumberFormat(valor) {
            return this.mostrarInformacion ? valor.toFixed(2) : '-';
        },
    },
}
</script>